import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import 'bootstrap'; 
import '@popperjs/core'; 
import "@fortawesome/fontawesome-free/js/all";

// Import Chart.js
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import 'chartjs-adapter-date-fns';

// Start Rails UJS, Turbolinks, and ActiveStorage
Rails.start();
Turbolinks.start();
ActiveStorage.start();

document.addEventListener('DOMContentLoaded', () => {
    const mapImage = document.getElementById('mapImage');
    const mapCanvas = document.getElementById('mapCanvas');
    const ctx = mapCanvas.getContext('2d');
  
    function resizeCanvas() {
      // Set canvas dimensions to match the image
      mapCanvas.width = mapImage.clientWidth;
      mapCanvas.height = mapImage.clientHeight;
  
      // Redraw any custom content, like lines, on the canvas here
      drawVehicleLines();
    }
  
    function drawVehicleLines() {
      // Example of drawing lines on the canvas, adjust as needed
      ctx.clearRect(0, 0, mapCanvas.width, mapCanvas.height); // Clear previous drawings
      ctx.beginPath();
      ctx.moveTo(50, 50); // Example start position
      ctx.lineTo(200, 200); // Example end position
      ctx.strokeStyle = 'white';
      ctx.lineWidth = 2;
      ctx.stroke();
    }
  
    // Ensure canvas and image size are in sync when the window is resized
    window.addEventListener('resize', resizeCanvas);
  
    // Initial canvas setup on page load
    resizeCanvas();
});
